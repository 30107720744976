import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { FaDiscord, FaInstagram, FaTwitter } from 'react-icons/fa';
import './App.css';

const trayItems = [
  '/images/tray_sandwich.png',
  '/images/tray_eggs.png',
  '/images/tray_beer.png',
  '/images/tray_donuts.png',
  '/images/tray_wine.png',
  '/images/tray_bitcoin.png',
  '/images/tray_hotdog.png',
  '/images/tray_ring.png',
  '/images/tray_dino.png',
  '/images/tray_mojito.png',
  '/images/tray_fries.png',
  '/images/tray_martini.png',
];

function App() {
  const [count, setCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (count === trayItems.length -1 ) {
        setCount(0);
      } else {
        setCount(count + 1);
      }
    }, 750);
    return () => clearInterval(interval);
  }, [count]);
  

  return (
    <div className='wrapper'>
        <div className='ticker'>
          <Marquee speed={100} gradientColor={[255, 207, 0]} gradientWidth={50}>
            <div>
              10,000 Dog Butler NFTs
            </div>
            <div>
              <img src='/images/bone.png' className='bone' alt='bone' />
            </div>
            {/* <div>
              Minting Summer 2022
            </div> */}
            <div>
              <img src='/images/benson.png' className='benson' alt='bone' />
            </div>
            <div>
              #GetServed
            </div>
            <div>
              <img src='/images/bone.png' className='bone' alt='bone' />
            </div>
            <div>
              10,000 Dog Butler NFTs
            </div>
            <div>
              <img src='/images/benson.png' className='benson' alt='bone' />
            </div>
            {/* <div>
              Minting Summer 2022
            </div> */}
            <div>
              <img src='/images/bone.png' className='bone' alt='bone' />
            </div>
            <div>
              #GetServed
            </div>
            <div>
              <img src='/images/benson.png' className='benson' alt='bone' />
            </div>
          </Marquee>
        </div>
        <div className='main'>
            <div className='logo'>
                <img  src='/images/logo.png' alt='dog butlers nft' />
            </div>
            <div className='tray-container'>
                <img id='tray-img' src={trayItems[count]} alt='dog butlers tray' />
            </div>
            <div className='social-links'>
              <a className='social-link-item' href='https://www.instagram.com/dogbutlersnft' rel='noreferrer' target='_blank'>
                <FaInstagram />
              </a>
              <a className='social-link-item' href='https://twitter.com/dogbutlersnft' rel='noreferrer' target='_blank'>
                <FaTwitter />
              </a>
              {/* <a className='social-link-item' href='https://raritysniper.com/nft-drops-calendar' rel='noreferrer' target='_blank'>
                <img className='svg' src='/images/rarity-sniper.svg' alt='Rarity Sniper' />
              </a>
              <a className='social-link-item' href='https://nftcalendar.io/event/dog-butlers/' rel='noreferrer' target='_blank'>
                <img className='svg' src='/images/nftcalendar-logo.svg' alt='NFT Calendar' />
              </a> */}
              {/* <a className='social-link-item' href='https://icy.tools/calendar' rel='noreferrer' target='_blank'>
                <img className='svg' src='/images/icy-tools.svg' alt='Icy Tools' />
              </a> */}
              {/* <div className='social-link-item disable' data-tip data-for='registerTip'>
                <FaDiscord />
                <div className='sub-text'>Coming Soon</div>
              </div> */}
            </div>
        </div>
    </div>
  );
}

export default App;
